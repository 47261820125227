<template>
  <div>
    <v-card class="pa-4" min-width="320" min-height="300">
      <v-card-title class="panel-title primary--text">
        Order Details
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" xs="12" sm="6">
            <v-row class="text-row align-baseline">
              <div class="text-title">User:</div>
              <div class="text-bd">{{ wModel.email }}</div>
            </v-row>
            <v-row class="text-row align-baseline">
              <div class="text-title">Product:</div>
              <div class="text-bd">{{ wModel.product }}</div>
            </v-row>
            <v-row class="text-row align-baseline">
              <div class="text-title">Type:</div>
              <div class="text-bd">{{ wModel.type }}</div>
            </v-row>
            <v-row class="text-row align-baseline">
              <div class="text-title">Quantity:</div>
              <div class="text-bd">{{ wModel.quantity }}</div>
            </v-row>
            <v-row class="text-row align-baseline">
              <div class="text-title">Amount Paid:</div>
              <div class="text-bd">
                {{ formartCurrency(wModel.subtotal, false, true) }}
              </div>
            </v-row>
            <v-row class="text-row align-baseline">
              <div class="text-title">Delivery Fee:</div>
              <div class="text-bd">
                {{ formartCurrency(wModel.deliveryFee, false, true) }}
              </div>
            </v-row>
            <v-row class="text-row align-baseline">
              <div class="text-title">Purchase Date:</div>
              <div class="text-bd">{{ formatDateMed(wModel.createdAt) }}</div>
            </v-row>
            <v-row class="text-row align-baseline">
              <div class="text-title">Bank:</div>
              <div class="text-bd">{{ wModel.bank }}</div>
            </v-row>
            <v-row class="text-row align-baseline">
              <div class="text-title">Status:</div>
              <div class="text-bd">{{ wModel.status }}</div>
            </v-row>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="mt-6 justify-space-between">
          <v-btn @click="$emit('close-dialog')" large outlined color="primary"
            >Close</v-btn
          >
          <v-btn
            v-if="wModel.status == 'pending' && !loading"
            :loading="busy"
            @click="fulfill"
            large
            raised
            color="primary"
            >Fulfill Order</v-btn
          >
          <v-btn
            v-if="wModel.status != 'pending' && !loading"
            :loading="busy"
            @click="printReceipt({ user: user, data: wModel })"
            large
            disabled
            raised
            color="primary"
            >Print Receipt</v-btn
          >
        </v-row>
      </v-card-text>
    </v-card>
    <iframe
      id="ifr"
      ref="receiptIframe"
      style="margin-top: 200px; height: 0px; width: 0px; display: none"
    ></iframe>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    // Footer
  },
  props: ["game"],
  computed: {
    ...mapGetters(["formartCurrency", "formatDateMed"]),
  },
  created() {},
  methods: {
    ...mapActions("api", ["httpGet"]),
    ...mapActions(["showMessage"]),
    async fulfill() {
      this.busy = true;
      try {
        await this.httpGet({
          withAuth: true,
          url: "/admin_set_sale_done",
          data: { id: this.wModel.id },
        });
        this.showMessage({ text: "Product saved", error: false });
        this.$emit("refresh");
        this.printReceipt({ user: this.user, data: this.wModel });
      } catch (error) {
        this.showMessage({
          text: "Something went wrong, please try again.",
          error: true,
        });
      }
      this.busy = false;
    },
    // printReceipt: async function (data) {
    //   // console.log(data);
    //   localStorage.setItem("receipt", JSON.stringify(data));
    //   this.$refs.receiptIframe.setAttribute("src", "/receipt.html");
    // },
  },
  data() {
    return {
      busy: false,
      loading: false,
      search: "",
      wModel: { data: {} },
      user: {},
    };
  },
  watch: {
    // wModel() {
    //   this.getUser();
    // },
  },
};
</script>
<style lang="css" scoped>
.panel-title {
  font-family: "Poppins", "sans-serif";
  font-weight: bold;
  font-size: 20px;
}
.text-row {
  width: 280px;
  margin-bottom: 10px;
}
.text-title {
  font-family: "Poppins", "sans-serif";
  font-weight: 500;
  color: #777;
  margin-right: 10px;
}
.text-bd {
  font-family: "Poppins", "sans-serif";
  font-weight: bold;
  color: #555;
  font-size: 14px;
}
</style>

